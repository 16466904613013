/* stylelint-disable declaration-no-important */
.main-navigation__wrapper {
    @include mq($until: desktop) {
        grid-template-areas:
            'mobileMenuButton logo shippingText userMenu'
            'searchBar searchBar searchBar searchBar'
            'mobileQuickLinks mobileQuickLinks mobileQuickLinks mobileQuickLinks' !important;
    }
}

.mobile-menu__header {
    grid-template-areas:
        'mobileMenuButton logo shippingText userMenu' !important;
}

.main-navigation__shipping-text,
.mobile-menu__shipping-text {
    display: flex !important;
}
