// Custom Properties in mediashop
:root {
    // Buttons
    --bg-button-primary: var(--theme-primary);
    --background-product-buy-button: var(--theme-tertiary);

    //PDP-Buttons
    --pdp-background-buy-button: var(--theme-tertiary);
    --pdp-color-buy-button: var(--theme-dark);

    // Footer
    --footer-font-color: var(--theme-cta-font-color);

    // PLP-Search Results
    --plp-headline-color: var(--theme-cta-font-color);

    // Empty Cart Button
    --empty-cart-link: var(--theme-cta-font-color);

    // Newsletter Headline on tertiary theme
    --theme-on-tertiary-border: var(--theme-cta-font-color);
    --theme-on-tertiary: var(--theme-cta-font-color);

    //Live TV Link Button
    --quicklink-button-color: var(--theme-tertiary);

    // Headlines
    --headline-font-size-desktop: 40px;
    --headline-line-height-desktop: 40px;
    --headline-font-size-mobile: 28px;
    --headline-line-height-mobile: 24px;
    --headline-margin-bottom: 6px;
    --subheadline-font-size-mobile: 17px;
    --subheadline-line-height-mobile: 20px;

    // Phone & Email in Contact
    --color-highlight: var(--theme-cta-font-color);

    // Navigation
    --free-delivery-highlighted: var(--color-red);
    --main-navigation-grid-column-width-mobile: 50px minmax(40px, 70px) auto 125px;
    --main-navigation-grid-column-width-tablet: 50px minmax(40px, 70px) auto 142px;
    --main-navigation-grid-column-width-desktop: 112px 33% auto 142px;
    --main-logo-wrapper-min-height-mobile: 50px;
    --main-logo-height-mobile: auto;
    --main-logo-max-height-mobile: 60px;
}
